import { useFetch } from "nuxt/app";
import type { FetchOptions } from "ofetch";
import type { UseFetchOptions } from "nuxt/app";
import { useAuthStore } from "~/store/auth";
import { getBrowserInfo } from "~/utils/clientInfo";
import { storage } from "~/utils/consts";
import Cookie from "~/utils/cookie";

interface IRequestParams<T> extends UseFetchOptions<T> {
  gateway?: "web" | "mock" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
  headers?: FetchOptions["headers"];
}

export const getHeaders = (headers: FetchOptions["headers"], version: string): HeadersInit => {
  const ksid = Cookie.get(storage.ksid);
  let userAgent = "";
  let platformVersion = "omit empty";
  const deviceId = useCookie(storage.uniqDeviceID).value || "omit empty";

  if (import.meta.client) {
    userAgent = window.navigator.userAgent;

    const browserInfo = getBrowserInfo();
    platformVersion = `${browserInfo.os} ${browserInfo.browser} ${browserInfo.version}`;
  } else {
    const reqHeaders = useRequestHeaders();
    userAgent = reqHeaders["user-agent"] || "";
  }

  return {
    ...toValue(headers),
    "Content-Type": "application/json",
    "User-Agent": userAgent,
    "X-Platform-Version": platformVersion,
    "X-Device-Tag": ksid || "disabled",
    "X-Device-ID": deviceId,
    "X-App-Version": version || "",
    "X-Device-Platform": "Web",
  };
};

export const useTransport = async <R = void>(url: string, params?: IRequestParams<R>) => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();

  const { permissions, gateway = "web", headers, ...restParams } = params || {};

  let _headers = getHeaders(headers, config.public.version as string);

  if (gateway === "web") {
    _headers = {
      ..._headers,
      "X-Client-Name": "mymagnit",
    };
  }

  if (permissions?.jwt) {
    const JWT = await authStore.fetchJWTAccessToken();

    if (!JWT) {
      throw new Error("Отсутствует JWT токен");
    }

    const Authorization = `Bearer ${JWT}`;

    _headers = {
      ..._headers,
      Authorization,
    };
  }

  return useFetch(url, {
    baseURL: gateway === "web" ? "/webgate" : gateway === "mock" ? "/mock" : "/magnit-id",
    headers: _headers,
    ...restParams,
  });
};
