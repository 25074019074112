import { default as cookie_45policyWrqZjqr6gMMeta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as delete_accountPkSpf8zeEUMeta } from "/app/src/pages/delete_account.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as addzGpGQBav8wMeta } from "/app/src/pages/profile/cards/add.vue?macro=true";
import { default as indexld8FuUz4C2Meta } from "/app/src/pages/profile/cards/index.vue?macro=true";
import { default as _91couponId_93UK06K4xC8PMeta } from "/app/src/pages/profile/coupons/[couponId].vue?macro=true";
import { default as indexVkXwvc74zkMeta } from "/app/src/pages/profile/coupons/index.vue?macro=true";
import { default as editqGTiTNmkzCMeta } from "/app/src/pages/profile/edit.vue?macro=true";
import { default as favorite_45categoriestqWXpCwrWtMeta } from "/app/src/pages/profile/favorite-categories.vue?macro=true";
import { default as incompletepVbXbLiTn0Meta } from "/app/src/pages/profile/incomplete.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as mplus9o9afICjpKMeta } from "/app/src/pages/profile/mplus.vue?macro=true";
import { default as notificationmybsV63kHzMeta } from "/app/src/pages/profile/notification.vue?macro=true";
import { default as changendsoKev7geMeta } from "/app/src/pages/profile/payment-methods/change.vue?macro=true";
import { default as deleteXKWxQTZ86dMeta } from "/app/src/pages/profile/payment-methods/delete.vue?macro=true";
import { default as payM46bMrrcY2Meta } from "/app/src/pages/profile/payment-methods/pay.vue?macro=true";
import { default as _91promoId_93pXqtjxYg0wMeta } from "/app/src/pages/profile/personal-promotions/[promoId].vue?macro=true";
import { default as indexRpUsnaFHOoMeta } from "/app/src/pages/profile/personal-promotions/index.vue?macro=true";
import { default as stickersBfe1RcMBHOMeta } from "/app/src/pages/profile/stickers.vue?macro=true";
import { default as support4awhTn0jSTMeta } from "/app/src/pages/profile/support.vue?macro=true";
import { default as indexniqfSMMxZWMeta } from "/app/src/pages/profile/transactions/index.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as pushtokennSUtf65U9RMeta } from "/app/src/pages/pushtoken.vue?macro=true";
import { default as qr4xFs4J9BZ6Meta } from "/app/src/pages/qr.vue?macro=true";
import { default as indexNbUvxHm6A3Meta } from "/app/src/pages/registration/index.vue?macro=true";
import { default as subscriptionML5heDAjOdMeta } from "/app/src/pages/subscription.vue?macro=true";
import { default as chatlTZTiY5WIPMeta } from "/app/src/pages/support/chat.vue?macro=true";
import { default as todayfuO7Y3P8Z2Meta } from "/app/src/pages/today.vue?macro=true";
export default [
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/src/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "delete_account",
    path: "/delete_account",
    component: () => import("/app/src/pages/delete_account.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: profileTx35vCKuH1Meta?.name,
    path: "/profile",
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-cards-add",
    path: "cards/add",
    component: () => import("/app/src/pages/profile/cards/add.vue").then(m => m.default || m)
  },
  {
    name: "profile-cards",
    path: "cards",
    component: () => import("/app/src/pages/profile/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-coupons-couponId",
    path: "coupons/:couponId()",
    component: () => import("/app/src/pages/profile/coupons/[couponId].vue").then(m => m.default || m)
  },
  {
    name: "profile-coupons",
    path: "coupons",
    component: () => import("/app/src/pages/profile/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "edit",
    component: () => import("/app/src/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile-favorite-categories",
    path: "favorite-categories",
    component: () => import("/app/src/pages/profile/favorite-categories.vue").then(m => m.default || m)
  },
  {
    name: "profile-incomplete",
    path: "incomplete",
    component: () => import("/app/src/pages/profile/incomplete.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "",
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-mplus",
    path: "mplus",
    component: () => import("/app/src/pages/profile/mplus.vue").then(m => m.default || m)
  },
  {
    name: "profile-notification",
    path: "notification",
    component: () => import("/app/src/pages/profile/notification.vue").then(m => m.default || m)
  },
  {
    name: "profile-payment-methods-change",
    path: "payment-methods/change",
    component: () => import("/app/src/pages/profile/payment-methods/change.vue").then(m => m.default || m)
  },
  {
    name: "profile-payment-methods-delete",
    path: "payment-methods/delete",
    component: () => import("/app/src/pages/profile/payment-methods/delete.vue").then(m => m.default || m)
  },
  {
    name: "profile-payment-methods-pay",
    path: "payment-methods/pay",
    component: () => import("/app/src/pages/profile/payment-methods/pay.vue").then(m => m.default || m)
  },
  {
    name: "profile-personal-promotions-promoId",
    path: "personal-promotions/:promoId()",
    component: () => import("/app/src/pages/profile/personal-promotions/[promoId].vue").then(m => m.default || m)
  },
  {
    name: "profile-personal-promotions",
    path: "personal-promotions",
    component: () => import("/app/src/pages/profile/personal-promotions/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-stickers",
    path: "stickers",
    component: () => import("/app/src/pages/profile/stickers.vue").then(m => m.default || m)
  },
  {
    name: "profile-support",
    path: "support",
    component: () => import("/app/src/pages/profile/support.vue").then(m => m.default || m)
  },
  {
    name: "profile-transactions",
    path: "transactions",
    component: () => import("/app/src/pages/profile/transactions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "pushtoken",
    path: "/pushtoken",
    component: () => import("/app/src/pages/pushtoken.vue").then(m => m.default || m)
  },
  {
    name: "qr",
    path: "/qr",
    component: () => import("/app/src/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/app/src/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: subscriptionML5heDAjOdMeta || {},
    component: () => import("/app/src/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: "support-chat",
    path: "/support/chat",
    meta: chatlTZTiY5WIPMeta || {},
    component: () => import("/app/src/pages/support/chat.vue").then(m => m.default || m)
  },
  {
    name: "today",
    path: "/today",
    component: () => import("/app/src/pages/today.vue").then(m => m.default || m)
  }
]