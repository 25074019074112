import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Subscription:View": {
    ya: {
      eventName: "magnit_plusUp_view",
      chapter: "magnit",
      page: "plusUp",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:Subscribe:View": {
    ya: {
      eventName: "magnit_plusUp_subscribe_view",
      chapter: "magnit",
      page: "plusUp",
      element: "subscribe",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:Subscribe:Click": {
    ya: {
      eventName: "magnit_plusUp_subscribe_click",
      chapter: "magnit",
      page: "plusUp",
      element: "subscribe",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:GoApp:View": {
    ya: {
      eventName: "magnit_plusUp_goApp_view",
      chapter: "magnit",
      page: "plusUp",
      element: "goApp",
    },
  },
  "Subscription:GoApp:Click": {
    ya: {
      eventName: "magnit_plusUp_goApp_click",
      chapter: "magnit",
      page: "plusUp",
      element: "goApp",
    },
  },
  "Subscription:Success:View": {
    ya: {
      eventName: "magnit_plusUp_success_view",
      chapter: "magnit",
      page: "plusUp",
      element: "success",
    },
  },
  "Subscription:FailDeleteCard:View": {
    ya: {
      eventName: "magnit_paymentMethod_failDeleteCard_view",
      chapter: "magnit",
      page: "payment",
      element: "failDeleteCard",
    },
  },
  "Subscription:FailAddCard:View": {
    ya: {
      eventName: "magnit_paymentMethod_failAddCard_view",
      chapter: "magnit",
      page: "payment",
      element: "failAddCard",
    },
  },
  "Subscription:Payment:Success:View": {
    ya: {
      eventName: "magnit_payment_success_view",
      chapter: "magnit",
      page: "payment",
      element: "success",
    },
  },
  "Subscription:Payment:FailPay:View": {
    ya: {
      eventName: "magnit_payment_failPay_view",
      chapter: "magnit",
      page: "payment",
      element: "failPay",
    },
  },
  "Subscription:Payment:View": {
    ya: {
      eventName: "magnit_payment_view",
      chapter: "magnit",
      page: "payment",
      element: "pay",
    },
  },
  "Subscription:Payment:Pay:View": {
    ya: {
      eventName: "magnit_payment_pay_view",
      chapter: "magnit",
      page: "payment",
      element: "pay",
    },
  },
  "Subscription:Payment:Pay:Click": {
    ya: {
      eventName: "magnit_payment_pay_click",
      chapter: "magnit",
      page: "payment",
      element: "pay",
    },
  },
  "Subscription:Payment:Change:View": {
    ya: {
      eventName: "magnit_payment_changePayment_view",
      chapter: "magnit",
      page: "payment",
      element: "changePayment",
    },
  },
  "Subscription:Payment:Change:Click": {
    ya: {
      eventName: "magnit_payment_changePayment_click",
      chapter: "magnit",
      page: "payment",
      element: "changePayment",
    },
  },
  "Subscription:PaymentMethod:View": {
    ya: {
      eventName: "magnit_paymentMethod_view",
      chapter: "magnit",
      page: "paymentMethod",
    },
  },
  "Subscription:PaymentMethod:Save:View": {
    ya: {
      eventName: "magnit_paymentMethod_save_view",
      chapter: "magnit",
      page: "paymentMethod",
      element: "save",
    },
  },
  "Subscription:PaymentMethod:Save:Click": {
    ya: {
      eventName: "magnit_paymentMethod_save_click",
      chapter: "magnit",
      page: "paymentMethod",
      element: "save",
    },
  },
  "Subscription:PaymentMethod:NewCard:View": {
    ya: {
      eventName: "magnit_paymentMethod_newCard_view",
      chapter: "magnit",
      page: "paymentMethod",
      element: "newCard",
    },
  },
  "Subscription:PaymentMethod:NewCard:Click": {
    ya: {
      eventName: "magnit_paymentMethod_newCard_click",
      chapter: "magnit",
      page: "paymentMethod",
      element: "newCard",
    },
  },
  "Subscription:PaymentMethod:Error:View": {
    ya: {
      eventName: "magnit_paymentMethod_error_view",
      chapter: "magnit",
      page: "paymentMethod",
      element: "error",
    },
  },
};
