export const getRange = (start: number, end: number): number[] => {
  return [...Array(end - start + 1).keys()].map((x) => x + start);
};

export const generateUniq = (length = 10) =>
  Math.random()
    .toString(16)
    .slice(2, length + 2);

// Генерируем случайную последовательность "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
export const generateUUID4 = () =>
  `${generateUniq(8)}-${generateUniq(4)}-${generateUniq(4)}-${generateUniq(4)}-${generateUniq(12)}`;

// Преобразуем номер карты в красивый вид "XXXX XXXX XXXX XXXX"
export const formatCreditCard = (number: string): string =>
  number.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");

export const getDecimalPart = (number: number): string => number.toString().split(".")[1] || "0";

export const formatNumberWithSpaces = (x: number): string => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(",");
};

export const formatCouponCode = (str: string): string => str.replace(/(.{4})/g, "$1-");

export const getScrollPercentage = (): number => {
  const documentElement = document.documentElement;
  const bodyElement = document.body;

  return (
    ((documentElement.scrollTop || bodyElement.scrollTop) /
    ((documentElement.scrollHeight || bodyElement.scrollHeight) - documentElement.clientHeight)) *
    100
  );
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    const intlCode = match[1] ? "+7 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4], "-", match[5]].join("");
  }
  return "";
};

export const formatPhoneNumberClean = (phoneNumber: string): string => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    const intlCode = match[1] ? "+7 " : "";
    return [intlCode, match[2], match[3], match[4], match[5]].join(" ");
  }
  return "";
};

export const reloadPage = () => {
  if (typeof window !== "undefined") {
    window.location.reload();
  }
};

export const isElementVisible = (element: HTMLElement) => {
  return Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

export const loadScript = (src: string, async = true): Promise<void> => {
  return new Promise((resolve, reject) => {
    const element = document.createElement("script");

    element.setAttribute("src", src);
    element.setAttribute("type", "text/javascript");
    if (async) {
      element.setAttribute("async", "true");
    }

    element.onload = () => {
      resolve();
    };

    element.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(element);
  });
};

export function getRndInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formatToUid = (str: string): string => {
  const match = str.match(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/);
  return match ? match.slice(1).join("-") : "";
};
