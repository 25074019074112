import { cookiePolicyRoute } from "@magnit/unit-cookie-policy/src/cookie-policy.vars";

export enum RouteNames {
  Landing = "login",
  Main = "today",
  Coupons = "profile-coupons",
  Profile = "profile",
  QrCode = "qr",
}

export enum Routes {
  Landing = "/",
  Main = "/today",
  Chat = "/support/chat",
  Profile = "/profile",
  ProfileTransactions = "/profile/transactions",
  ProfileEdit = "/profile/edit",
  ProfileNotification = "/profile/notification",
  ProfileSupport = "/profile/support",
  ProfileMPlus = "/profile/mplus",
  ProfileCards = "/profile/cards",
  ProfileCardsAdd = "/profile/cards/add",
  ProfileStickers = "/profile/stickers",
  ProfileCoupons = "/profile/coupons",
  ProfileIncomplete = "/profile/incomplete",
  ProfileFavoriteCategories = "/profile/favorite-categories",
  ProfilePersonalPromotions = "/profile/personal-promotions",
  PersonalPromotionsMock = "/test/personal-promotions",
  ProfileDelete = "/delete_account",
  QrCode = "/qr",
  Login = "/login",
  Registration = "/registration",
  CookiePolicy = cookiePolicyRoute,
  Subscription = "/subscription",
  PaymentMethods = "/profile/payment-methods",
  PaymentMethodsPay = "/profile/payment-methods/pay",
  PaymentMethodsChage = "/profile/payment-methods/change",
  PaymentMethodsDelete = "/profile/payment-methods/delete",
}

export const routeParams = {
  firstAddCard: "firstAddCard",
  withMergeCardModal: "withMergeCardModal",
  withPushSubscription: "withPushSubscription",
  tab: "tab",
  edit: "edit",
};

export enum RedirectedRoutes {
  App = "/app",
  FullEnrollment = "/full-enrollment",
  Promo = "/promo",
  Dashboard = "/dashboard",
  Enrollment = "/enrollment",
  Terms = "/terms",
  Offers = "/offers",
  MemberProfile = "/member-profile",
  Transactions = "/transactions",
}

export enum ExternalRoutes {
  MagnitPromopokarte = "https://magnit.ru/offer/promopokarte/",
}

export const isExternal = (link: string) => /^http(s)?:\/\//i.test(link);
