import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import eventsTransactions from "./transactions";
import eventsFC from "./fc";
import eventsAccruals from "./accruals";
import eventsStickers from "./stickers";
import eventsBonuses from "./bonuses";
import eventsMyCard from "./myCard";
import eventsSettings from "./settings";
import eventsCoupons from "./coupons";
import eventsPersonalPromotions from "./personalPromotions";
import eventsLogin from "./login";
import eventsLanding from "./landing";
import eventsRegistration from "./registration";
import eventsProfileIncomplete from "./profileIncomplete";
import eventsAddCard from "./addCard";
import eventsMain from "./main";
import eventsNavbar from "./navbar";
import eventsApp from "./app";
import eventsSubscription from "./subscription";

export default <IAnalyticEventsConfig>{
  ...eventsNavbar,
  ...eventsApp,
  ...eventsLanding,
  ...eventsLogin,
  ...eventsRegistration,
  ...eventsProfileIncomplete,
  ...eventsAddCard,
  ...eventsMain,
  ...eventsPersonalPromotions,
  ...eventsCoupons,
  ...eventsSettings,
  ...eventsMyCard,
  ...eventsBonuses, // ToDo: Remove
  ...eventsStickers,
  ...eventsAccruals, // ToDo: Remove
  ...eventsFC,
  ...eventsTransactions,
  ...eventsSubscription,
};
