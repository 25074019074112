import { defineStore } from "pinia";
import { ref } from "vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";

export const MODALS = {
  Login: "login",
  Logout: "logout",
  CardsInfo: "cards-info",
  ProfileDelete: "profile-delete",
  EmailConfirm: "email-confirm",
  Calendar: "calendar",
  CardGuide: "card-guide",
  CardMagnit: "card-magnit",
  CardMerge: "card-merge",
  CardMergeDelay: "card-merge-delay",
  CouponExpired: "coupon-expired",
  Install: "install",
  InstallSuccess: "install-success",
  FavoriteCategories: "favorite-categories",
  FavoriteCategoriesItem: "favorite-categories-item",
  FavoriteCategoriesHowItWork: "favorite-categories-how-it-work",
  PwaIosInstruction: "pwa-ios-instruction",
  MagnetsHowToCollect: "magnets-how-to-collect",
  Push: "push",
  PushFullscreen: "push-fullscreen",
  PushInstruction: "push-instruction",
  TransactionsTypeSelect: "transactions-type-select",
  TransactionsStoreTypeSelect: "transactions-store-type-select",
  TransactionsMonthSelect: "transactions-month-select",
  Transaction: "transaction",
  PaymenMethodBind: "payment-method-bind",
} as const;

type Keys = keyof typeof MODALS;
export type IModalsName = (typeof MODALS)[Keys] | string;
export interface IModalOptions {
  clearable?: boolean;
  manual?: boolean;
  params?: unknown;
}

export const useModalsStore = defineStore("modals", () => {
  const openedModals = ref<string[]>([]);
  const modalPromiseMap: Map<IModalsName, () => void> = new Map();

  const getActive = (): string | null =>
    openedModals.value.length > 0 ? openedModals.value[0] : null;

  const isOpen = (modal: IModalsName): boolean => openedModals.value.includes(modal);

  const open = (name: IModalsName, clearable?: boolean, manual?: boolean) => {
    if (clearable) {
      openedModals.value = [name];
    } else {
      openedModals.value.unshift(name);
    }

    if (!manual) {
      scrollLock.enable();
    }
    return new Promise<void>((resolve) => {
      modalPromiseMap.set(name, resolve);
    });
  };

  const close = (name: IModalsName) => {
    const resolve = modalPromiseMap.get(name);
    if (resolve) {
      modalPromiseMap.delete(name);
      resolve();
    }

    openedModals.value = openedModals.value.filter((n) => n !== name);

    if (!openedModals.value.length) {
      scrollLock.disable();
    }
  };

  const closeAll = () => {
    openedModals.value.forEach(close);
    scrollLock.disable();
  };

  return {
    getActive,
    open,
    close,
    closeAll,
    isOpen,
    openedModals,
  };
});
